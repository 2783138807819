import Ractive from 'ractive';
import $ from "jquery";
import TweenMax from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Router from '../../src/ractive-router';
import GuideSelector from './guide-selector';
import 'slick-carousel';
import MapCard from './map-card';

export default Ractive.extend({
    template: `
      <div id="map" >
        <div class="map-filter">
           <div class="filter-holder">
            <div class="filter-head">
              <img class="list-icon" src="./images/list_icon.svg"/>
              <span class="filter_title">All Locations</span>
              <img class="filter-arrow" src="./images/chevron.svg"/>
            </div>
                <div class="filter-locations">
                    <ul>
                    {{#each filters}}
                      <li>
                        <label for="{{.}}">
                          <input type='checkbox' id="{{.}}" name="{{activeFilters}}" value="{{.}}">
                          <span></span>{{(.).replace("-", " & ")}}
                        </label>
                      </li>
                    {{/each}}
                    </ul>
                </div>
                <div class="apply-filter" on-click="applyFilters">Apply Filter</div>
                <div class="clear-filter">Clear Selection</div>
           </div>
          </div>
        <GuideSelector />

        <div id="map_canvas"><noscript><p>JavaScript is required to render the Google Map.</p></noscript></div>

        <div class="map_cards_container primay_green">
          <div class="map-cards-container-inner">
            {{#each mapCards: num}}
              <MapCard data={{.}} class="card-{{num}}" index="{{num}}"/>
            {{/each}}
              <div class="map_cards_footer">
                  <div class="next_prompt">
                      <h3>WHERE NEXT?</h3>
                      <a href="https://uel.ac.uk/be-the-change" class="button blue" target="_blank" title="Visit UEL">Visit UEL</a>
                  </div>
                  <div class="other_avatars">
                      <div class="avatar" data-name="{{bottomGuides[0].name}}" on-click="scrollGuideSelected">
                          <div class="bg-circle">
                              <img src="{{bottomGuides[0].avatar}}" alt="Switch Guide" title="Switch Guide">
                          </div>
                          <img src="{{bottomGuides[0].switchArrow}}" class="switch" alt="Switch Guide" title="Switch Guide">
                      </div>
                      <div class="avatar" data-name="{{bottomGuides[1].name}}" on-click="scrollGuideSelected">
                          <div class="bg-circle">
                              <img src="{{bottomGuides[1].avatar}}" alt="Switch Guide" title="Switch Guide">
                          </div>
                          <img src="{{bottomGuides[1].switchArrow}}" class="switch" alt="Switch Guide" title="Switch Guide">
                      </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="map_prompt map">
            <div class="map_prompt_wrapper">
              <div class="desc">
                <div class="icon"><img class="play-button" src="./images/prompt.png"/></div>
                Join our east London community
              </div>
              <a href="https://uel.ac.uk/be-the-change" class="button" target="_blank" title="Apply to UEL">Apply to UEL</a>
            </div>
          </div>
          <a class="visit_uel_mobile" href="https://uel.ac.uk/be-the-change" target="_blank" title="Visit UEL">Visit UEL</a>
      </div>
      <div class="page_footer">
          <div class="copyright">Copyright ©2022 University of East London</div>
          <div class="footer_links">
            <a href="https://www.uel.ac.uk/about/governance/data-protection" target="_blank" title="Privacy policy">Privacy policy</a>
            <a href="#cookie-policy" title="Cookie policy">Cookie policy</a>
          </div>
        </div>

    `,
    data: () => {
      return {
        activeFilters: [],
        filters: [],
        mapCards: [],
        guides: [
          {
            name: "lauren",
            avatar: "./images/lauren/lauren_avatar.jpg",
            switchArrow: "./images/switch_green.svg"
          },
          {
            name: "saiful",
            avatar: "./images/saiful/saiful_avatar.jpg",
            switchArrow: "./images/switch_pink.svg"
          },
          {
            name: "uma",
            avatar: "./images/uma/uma_avatar.jpg",
            switchArrow: "./images/switch_orange.svg"
          },
          {
              name: "neutral",
              avatar: "./images/map_with_pin.png",
              switchArrow: "./images/switch_orange.svg"
          }
        ],
        bottomGuides: []
      }

    },
    components: {
      GuideSelector: GuideSelector,
      MapCard: MapCard
    },
    cardIndex: 0,
    markers: [],
    markersInactive: [],
    isScrollAnimate: false,

    showGuideSelector () {
      this.findComponent('GuideSelector').show();
    },
    renderBottomGuides (guideIndex) {

      if (guideIndex + 1 === parseInt(this.get('routeParams.id'))) {
        this.findComponent('GuideSelector').close();
      }

      this.get('bottomGuides').length = 0;
      for (var i = 0; i < this.get('guides').length; i++) {
        if (i !== guideIndex) {
          this.get('bottomGuides').push(this.get('guides')[i])
        }
      }
    },

    switchGuides (aName) {
      switch(aName) {
        case 'lauren':
          Router.go('#map/1');
          this.renderBottomGuides(0);
        break;
        case 'saiful':
          Router.go('#map/2');
          this.renderBottomGuides(1);
        break;
        case 'uma':
          Router.go('#map/3');
          this.renderBottomGuides(2);
        break
        case 'neutral':
          Router.go('#map/4');
          this.renderBottomGuides(3);
        break
      }
    },

    scrollTriggerMarkers (self) {
      $(".map_cards_inner").each((index, el) => {

        if ($('.map_cards_container')[0].scrollTop >= el.offsetTop && $('.map_cards_container')[0].scrollTop < el.offsetTop + el.offsetHeight) {

          for (var i = 0; i < self.markers.length; i++) {

            if (self.markers[i].get('isBlue')) {
              self.markers[i].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue'));
            }
            else {
              self.markers[i].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarker'));
            }
            var id = self.markers[i].get('id')
            if (parseInt(id) === $(el).data('index')) {
              self.cardIndex = id;
              if (self.markers[i].get('isBlue')) {
                self.markers[i].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlueIcon'));

              }
              else {
                self.markers[i].setIcon(self.get('mapCards')[parseInt(id)].card.mapmarkerplay)
                //self.markers[i].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') +']').locations[parseInt(id)].card.mapmarkerplay);
              }
            }
          }
        }

      });
    },

    renderMarkersOnMap() {
      console.log('render markers')
      // this.updateModel();
      // this.update();
      //Router.go('#map/'+ this.get('routeParams.id'));

      let self = this;
      $('#map_canvas').empty();
      // console.log(self.get('parentGlobals.guides['+ self.get('routeParams.id') +']'));
      window.initialise = () => {
        // create object literal to store map properties
        var myOptions = {
          disableDefaultUI: true,
          zoom: 14 // set zoom level
          , mapTypeId: google.maps.MapTypeId.ROADMAP // apply tile (options include ROADMAP, SATELLITE, HYBRID and TERRAIN),
          , styles: this.mapStyles
          , backgroundColor: "#393939"
        };

        // create map object and apply properties
        var map = new google.maps.Map( document.getElementById( "map_canvas" ), myOptions );

        // create map bounds object
        var bounds = new google.maps.LatLngBounds();

        var guides = ractive.get('globals.guides');
        // console.log();
        var unfiltered_locations = guides[parseInt(self.get('routeParams.id'))].locations;

        //GET POSSIBLE FILTERS FOR CURRENT GUIDE
        var current_guide_filters = [];
          $(unfiltered_locations).each(function(e,data){
              var LocationTags = data.tags;
              $(LocationTags).each(function(e,data){
                 current_guide_filters.push(data);
              });
          });


          function unique(array){
              return array.filter(function(el, index, arr) {
                  return index === arr.indexOf(el);
              });
          }


          var current_guide_filterss = unique(current_guide_filters);
          this.set('filters', current_guide_filterss)
          console.log('filters11',this.get('filters'))

        //
        //   $('.filter-locations ul').empty()
        //
        //   $(current_guide_filterss).each(function(e,data){
        //       $('.filter-locations ul').append("<li><label for="+data+"><input type='checkbox' id="+data+" name="+data+" value="+data+"><span></span>"+data+"</label></li>");
        //   });
        //
        //   //var ActiveFilters = JSON.parse(localStorage.getItem('ActiveFilters1'));
        //   var ActiveFilters = self.get('activeFilters');
        //   console.log('ractive filters', self.get('activeFilters'));
        //
        //     $(ActiveFilters).each(function(e,data){
        //         $('#'+data).prop('checked', true);
        //     });
        //
        //     // console.log();
        //     if (ActiveFilters && ActiveFilters.length) {
        //       $('.filter_title').text('Filtered Locations');
        //     } else {
        //       $('.filter_title').text('All Locations');
        //
        //     }
        //
        //
        //   //REMOVE ITEMS FROM ARRAY THAT ARE FILTERED
        // $(unfiltered_locations).each(function(e,data){
        //     //var ActiveFilters = JSON.parse(localStorage.getItem('ActiveFilters1'));
        //     var ActiveFilters = self.get('activeFilters');
        //     var LocationTags = data.tags;
        //     var Shouldshow;
        //
        //     $(ActiveFilters).each(function(e,data){
        //         if($.inArray(String(data),LocationTags) > -1){
        //             Shouldshow = true;
        //         } else {
        //             Shouldshow = false;
        //         }
        //     });
        //
        //     if(Shouldshow === false) {
        //         unfiltered_locations.splice( $.inArray(data, unfiltered_locations), 1 );
        //     }
        //
        // });

        console.log('guide filters', current_guide_filterss)
        console.log('un filters', unfiltered_locations)

        let filteredLocations = unfiltered_locations.filter(location =>
          this.get('activeFilters').some(activeFilter => {
            //var arrFilter = activeFilter.split();
            return location.tags.includes(activeFilter);
          }
          ));
        console.log("filtered locations1",filteredLocations);

        if (filteredLocations.length === 0) {
          filteredLocations = guides[parseInt(self.get('routeParams.id'))].locations;
        }

        this.set('mapCards', filteredLocations);

        const NEUTRAL_MAP = 4;
        // loop through locations and add to map
        for ( var i = 0; i < guides.length; i++)
        {

          if (i === parseInt(self.get('routeParams.id'))) {

            var mapCards = this.get('mapCards');
            for(var k = 0; k < mapCards.length; k++) {

              // get current location
              var location = mapCards[k].location;
              var isBlue = mapCards[k].isBlue;

              // create map position
              var position = new google.maps.LatLng( location.lat, location.lng );

              // add position to bounds
              bounds.extend( position );
              let mapMarker;
              if (isBlue) {
                //mapMarker = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue');
                //mapMarker = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue');
                mapMarker = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue');

              }
              else {
               //mapMarker = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarker')
               mapMarker = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarker')
              }

              var marker = new google.maps.Marker({
                //animation: google.maps.Animation.
                 icon: mapMarker  //"http://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png"
                , map: map
                , zIndex: 100
                , position: position
                , title: this.get('mapCards')[k].name
                , guideNumber: i.toString()
                , id: k.toString()
                , class : 'mapmark'
                ,isBlue: isBlue
              });

              self.markers.push(marker);

              // create info window and add to marker (https://developers.google.com/maps/documentation/javascript/reference#InfoWindowOptions)
              google.maps.event.addListener( marker, 'click', (
                function( marker, k ) {

                  return function() {
                    for (var j = 0; j < self.markers.length; j++) {

                      //if (parseInt(self.get('routeParams.id')) !== NEUTRAL_MAP) {
                        if (self.markers[j].get('isBlue')) {
                          self.markers[j].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue'))
                        }
                        else {
                          self.markers[j].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarker'))
                        }
                      // }
                      // else {
                      //     self.markers[j].setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlue'));
                      // }

                    }
                    //if (parseInt(self.get('routeParams.id')) !== NEUTRAL_MAP) {
                      if (marker.get('isBlue')) {
                        marker.setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlueIcon'));

                      }
                      else {
                        var test = mapCards[k].card.mapmarkerplay;

                        marker.setIcon(test);
                      }
                    //}
                    // else {
                    //   marker.setIcon(self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlueIcon'));
                    // }
                    self.isScrollAnimate = true;
                    self.cardIndex = k;
                    TweenMax.to('.map_cards_container', {duration: .3, scrollTo:{
                      y:'.card-'+k
                    }, onComplete: ()=> {
                      self.isScrollAnimate = false;
                    }});

                  }
                }
              )( marker, k ) );

                google.maps.event.addListener(marker,'mouseover',function(e){
                    $(this).addClass('what')
                });
            }
          }
          else {

            if (i > 0) {

              if (parseInt(self.get('routeParams.id')) === NEUTRAL_MAP) {
                continue
              }
              var locations = [];
              if (i !== NEUTRAL_MAP) {
                locations = guides[i].locations;
               }
               else {
                 var concatLoc = [].concat(guides[1].locations, guides[2].locations, guides[3].locations);
                 locations = concatLoc.filter(location => !guides[4].locations.includes(location.ID));
               }

              for(var l = 0; l < locations.length; l++) {

                // get current location
                  //console.log('loc',i,l, locations, guides[i].locations)
                var location = guides[i].locations[l].location;

                // create map position
                var position = new google.maps.LatLng( location.lat, location.lng );
                var iconUrl = self.get('parentGlobals.guides['+ i + '].mapMarkerInactive')
                var icon = {
                    url: iconUrl,
                    scaledSize: new google.maps.Size(26, 26), // scaled size
                };
                var inactiveMarker = new google.maps.Marker({
                  //animation: google.maps.Animation.DROP
                   icon:   icon //"http://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png"
                  , map: map
                  , zIndex: 1
                  , position: position
                  , title: guides[i].locations[l].name
                  , guideNumber: i.toString()
                  , id: l.toString()

                  //, title: location[ 0 ]
                });

                google.maps.event.addListener( inactiveMarker, 'click', (
                  function( inactiveMarker, l ) {

                    return function() {
                      var guideNum = inactiveMarker.get('guideNumber');
                      var markerId = inactiveMarker.get('id');
                        localStorage.removeItem("ActiveFilters");
                        Router.go('#map/'+ guideNum +'/' + markerId);

                    }
                  }
                )( inactiveMarker, l ) );

              }
            }

          }
        };

        // fit map to bounds
        map.fitBounds( bounds );
        var listener = google.maps.event.addListener(map, "idle", function() {
          map.setZoom(13);

           if (window.outerWidth < 768) {
             map.panBy(0,0);

           }
           else {
             map.panBy(200,0);

           }
           google.maps.event.removeListener(listener);
      });

        if (self.get('routeParams.locationId')) {
          for(var m = 0; m < self.markers.length; m++) {
            var markerId = parseInt(self.markers[m].get('id'));
            var locationId = parseInt(self.get('routeParams.locationId'));
            if (markerId === locationId) {
              self.isScrollAnimate = true;
              if (self.markers[m].get('isBlue')) {
                let bluemarkers = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerBlueIcon');
                self.markers[m].setIcon(bluemarkers);
              }
              else {
                let playMarkers = self.get('parentGlobals.guides['+ self.get('routeParams.id') + '].mapMarkerPlay')
                self.markers[m].setIcon(playMarkers);
              }
              self.cardIndex = m;
              TweenMax.to('.map_cards_container', {delay: 2, duration: .3, scrollTo:{
                y:'.card-'+m
              }, onComplete: ()=> {
                self.isScrollAnimate = false;
                self.scrollTriggerMarkers(self);
              }});
            }
          }
        }

      }
      this.loadScript();
    },

    onrender() {

      TweenMax.registerPlugin(ScrollToPlugin);
      var prevMarker = undefined;
      var currMarker = undefined;
      var self = this;

      self.markers.forEach(marker => marker.setMap(null));
      self.markersInactive.forEach(marker => marker.setMap(null));

      self.markersInactive = [];
      self.markers = [];

      this.renderBottomGuides(parseInt(self.get('routeParams.id'))-1);

      this.on({
        'scrollGuideSelected': (aEvent) => {
          this.switchGuides($(aEvent.node).data('name'));
            this.set('activeFilters', []);
        },
        '*.guideSelected': (aName)=> {
          this.switchGuides(aName);
          this.set('activeFilters', []);
        },
        'applyFilters':  (aEvent) => {
           //Router.go('#map/'+ self.get('routeParams.id'));
          // if ( window.location.href.indexOf('?filters=') > -1) {
          //     let filtersFromUrl = window.location.href.split('?filters=')[1];
          //     this.set('activeFilters', filtersFromUrl.split(','));
          // }

            var af = this.get('activeFilters')
            if (af.length > 0) {
              Router.go('#map/'+ self.get('routeParams.id') +'?filters='+af);
            }
            else {
              Router.go('#map/'+ self.get('routeParams.id'));
            }

        }
      });

      TweenMax.from(".map_cards_inner", {
        delay: 1,
        opacity: 0,
        y: 20,
        ease: 'power4' // 0.1 seconds between when each ".box" element starts animating
      });

      let scrollTimeout = undefined;

        $(this.find('.clear-filter')).on('click', ()=> {
              self.set('activeFilters',[]);
              Router.go('#map/'+ self.get('routeParams.id'));
        });

      $(this.find('.map_cards_container')).on('scroll', ()=> {
        if (!this.isScrollAnimate) {
          this.scrollTriggerMarkers(this)
        }
      });

      $(this.find('.map_cards_container')).on('touchstart', ()=> {
        if (!$(this.find('.map_cards_container')).hasClass('scrolling')) {
            $(this.find('.map_cards_container')).addClass('scrolling');
            $('.site_logo').hide('fast');
            $('.site_avatar').hide('fast');
            $('.map-filter').hide('fast');
        }

        if (scrollTimeout) {
          clearTimeout(scrollTimeout);
        }

      });

        $(this.find('.map_cards_container')).on('touchend', ()=> {
         scrollTimeout = setTimeout(()=>{
           self.isScrollAnimate = true;

           TweenMax.to('.map_cards_container', { duration: .3, scrollTo:{
             y:'.card-' + self.cardIndex,
             onComplete: ()=> {
               self.isScrollAnimate = false;
               $(this.find('.map_cards_container')).removeClass('scrolling');
               $('.site_logo').show('fast');
               $('.site_avatar').show('fast');
               $('.map-filter').show('fast');

             }
           }})
       }, 3000);
     })

        $(this.find('.filter-head')).on('click', ()=> {
            $('.filter-holder').toggleClass('show-filter');
        });

        this.renderMarkersOnMap();

      setTimeout(()=> {
        this.fire('showAvatar', {id: this.get('routeParams.id'), isMap: true});
        this.fire('hideMapIcon');
      },100);
  },

  loadScript() {
    $("#map-script").remove();
    var script = document.createElement( "script" );
    script.id = "map-script"
    script.type = "text/javascript";
    //script.src = "http://maps.googleapis.com/maps/api/js?key=AIzaSyAaY9rOAgZg1IjvGw9ONtXaJKRdNRWPvsU&callback=initialise"; // staging key
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIcwGFJGGMrkuAnhgIiZETNRZy-p9PwFo&callback=initialise"; // live key
    document.body.appendChild( script );
  },

    onteardown () {
      $("#map-script").remove();
        this.off();
        $(this.find('.map_cards_container')).off();
    },
    mapStyles: [
      {
          "featureType": "all",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "saturation": 36
              },
              {
                  "color": "#000000"
              },
              {
                  "lightness": 40
              }
          ]
      },
      {
          "featureType": "all",
          "elementType": "labels.text.stroke",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#000000"
              },
              {
                  "lightness": 16
              }
          ]
      },
      {
          "featureType": "all",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 20
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 17
              },
              {
                  "weight": 1.2
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative.country",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "administrative.country",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "administrative.country",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "administrative.province",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative.locality",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "saturation": "-100"
              },
              {
                  "lightness": "30"
              }
          ]
      },
      {
          "featureType": "administrative.locality",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative.neighborhood",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative.neighborhood",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "administrative.land_parcel",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              },
              {
                  "gamma": "0.00"
              },
              {
                  "lightness": "74"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 20
              }
          ]
      },
      {
          "featureType": "landscape.man_made",
          "elementType": "all",
          "stylers": [
              {
                  "lightness": "3"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 21
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 17
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 29
              },
              {
                  "weight": 0.2
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 18
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 16
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 19
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
              {
                  "color": "#000000"
              },
              {
                  "lightness": 17
              }
          ]
      }
  ]
});
